import { Component } from '@angular/core';
import * as firebase from 'firebase';

import { fadeInAnimation } from 'src/app/_animations/fade-in.animation';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [fadeInAnimation]
})
export class AppComponent {
  constructor() {
    const firebaseConfig = {
      apiKey: "AIzaSyC5WsFFM9m1cTGRJ034LFmC-fvW-XWaVvY",
      authDomain: "lynxlab-website.firebaseapp.com",
      databaseURL: "https://lynxlab-website.firebaseio.com",
      projectId: "lynxlab-website",
      storageBucket: "lynxlab-website.appspot.com",
      messagingSenderId: "271142683679",
      appId: "1:271142683679:web:959bb7b0f211b06747012a"
    };

    firebase.initializeApp(firebaseConfig);
  }

  onActivate(event) {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20);
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 5);
  }
}
