import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { FirebaseService } from 'src/app/shared/services/firebase.service';
import { ContactMessage } from 'src/app/shared/models/contact-message.model';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.css']
})
export class ContactFormComponent implements OnInit {
  contactForm: FormGroup;
  @ViewChild('confirmSwal') private confirmSwal: SwalComponent;

  constructor(private formBuilder: FormBuilder, private firebase: FirebaseService) { }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.contactForm = this.formBuilder.group({
      fullName: ['', [Validators.required, Validators.minLength(2)]],
      email: ['', [Validators.required, Validators.email]],
      company: [''],
      budget: ['Quel est votre budget ?'],
      message: ['', [Validators.required, Validators.minLength(2)]],
    })
  }

  onSubmit() {
    if (this.contactForm.invalid) return false;

    let contactMessage: ContactMessage = new ContactMessage();
    contactMessage.fullName = this.contactForm.get('fullName').value;
    contactMessage.email = this.contactForm.get('email').value;
    contactMessage.company = this.contactForm.get('company').value;
    contactMessage.budget = this.contactForm.get('budget').value;
    contactMessage.message = this.contactForm.get('message').value;

    this.firebase.contactMessage.push(contactMessage);
    this.firebase.saveMessage();

    this.confirmSwal.fire();

    this.initForm();
  }

}
