import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";

@Component({
  selector: 'app-references',
  templateUrl: './references.component.html',
  styleUrls: ['./references.component.css']
})
export class ReferencesComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  getPadding() {
    if (this.router.url === '/') return '10px';
  }
}
