import {trigger, animate, transition, style, query} from '@angular/animations';
  
export const fadeInAnimation = trigger('fadeAnimation', [
  transition('* => *', [
    query(':enter', 
      [style({ opacity: 0 })], { optional: true }),
    query(':leave', 
      [style({ opacity: 1, filter: 'blur(15px)' }), 
        animate('0.2s', style({ opacity: 0 }))], { optional: true }),
    query(':enter', 
      [style({ opacity: 0, filter: 'blur(15px)' }), 
        animate('0.2s', style({ opacity: 1 }))], { optional: true })
  ])
]);