import { Injectable } from '@angular/core';
import { ContactMessage } from '../models/contact-message.model';
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  contactMessage: ContactMessage[] = [];

  constructor() { 
    this.getMessage();
  }

  getMessage() {
    firebase.database().ref('/contact').on('value', data => {
      this.contactMessage = data.val() || [];
    });
  }

  saveMessage() {
    firebase.database().ref('/contact').set(this.contactMessage);
  }
}
